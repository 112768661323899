<template>
<div class="container">

  <ErrorRow :error="error" />

</div>
</template>
<script>
import { mapGetters } from 'vuex';
import Catalog from "@/domain/session/Catalog.js";
//import Button   from '@/components/button/Button.vue';
import ErrorRow from '@/components/row/ErrorRow.vue';
import ConstUtils  from '@/utils/ConstUtils.js';

export default {
  name: 'portal-operator-checkin-details',
  components: {
//    Button,
    ErrorRow,
  },
  props: {
    isAdmin: { type: Boolean, default: false },
  },
  data() {
    return {
      error: null,
      list: [],
      isLoading: false,
      count: 0,
      total: 0,
      pageSize: 40,
      pageNumber: 0,
      K: Catalog.KEYS,
      C: new Catalog(),
    }
  },
  computed: {
    ...mapGetters([
        'auth_connected',
        'auth_client',
        'domain',
      ]),
    max: function() {
      var t = (this.pageNumber + 1) * this.pageSize;
      return this.count < t ? this.count : t;
    },
    min: function() {
      if (this.count == 0) {
        return 0;
      }
      return (this.pageNumber * this.pageSize) + 1;
    },
  },
  watch: {
  },
  mounted: function () {
    if (!this.auth_connected) {
      this.$router.replace({ name: this.$route.query.redirectTo || ConstUtils.ROUTES.SIGNIN })
    }
  },
  methods: {
  
  },
}
</script>